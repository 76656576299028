<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
import _ from 'lodash';


/**
 * Google-map component
 */
export default {
  page: {
    title: "Coverage Maps",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      markerOptions: {
        url: require('../../../assets/images/marker-map.png'),
        // url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png",
        // size: { width: 46, height: 46, f: "px", b: "px" },
        // scaledSize: { width: 23, height: 23, f: "px", b: "px" }
      },
      markerOptionsAdd: {
        url: require('../../../assets/images/login-img.png'),
        // url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/parking_lot_maps.png",
        // size: { width: 46, height: 46, f: "px", b: "px" },
        // scaledSize: { width: 23, height: 23, f: "px", b: "px" }
      },
      labelMarker: {
        text:"-",
        color: "white"
      },
      title: "ODB Maps",
      items: [
        {
          text: "ODB Maps",
          href: "/",
        }
      ],
      markers: [],
      markersAdd: [],
      pov: null,
      pano: null,
      edited: null,
      popupData: "",
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: '',
          //optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      optionsMap: {
        streetViewControl: false,
        mapTypeControl: false,
        gestureHandling: 'greedy'
      },
      place: null,
      mapCenterOptions: {
        strokeColor: '#1D5D9B',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#1D5D9B',
        fillOpacity: 0.05
      },
      mapCenterPosition: {
        lat: 0,
        lng: 0
      },
      dataRadius: {
        position: {
          lat: -7.8833655701050676,
          lng: 112.5246981539795
        },
        radius: 150
      }
    };
  },
  async mounted () {
    // this.getData()
    // console.log(this.$route.query)
    if(!_.isEmpty(this.$route.query) && _.has(this.$route.query, 'lat') && _.has(this.$route.query, 'lng')){
      await this.getMarkerPositionFromQuery(parseFloat(this.$route.query.lat), parseFloat(this.$route.query.lng))
    }
  },
  // watch: {
  //   'dataRadius.radius': {
  //     immediate: true,
  //     handler(newVal) {
  //       if (newVal !== "") {
  //         this.dataRadius.radius = Number(newVal)
  //       }
  //     },
  //   },
  // }, 
  methods: {
    getMarkerPosition(event){
      // console.log('mark', event)
      const pos = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      
      this.$gmapApiPromiseLazy().then(() => {
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ location: { lat: pos.lat, lng: pos.lng } }, (results, status) => {
          if (status === 'OK') {
            this.setDragPlace(results[0])
            this.$refs.autocomplete.$refs.input.value = results[0].formatted_address
          }
          // console.log('res', results[0])
          // console.log('stat', status)
        })
      })
    },
    async getMarkerPositionFromQuery(lat, lng){
      // console.log('jalan')
      const pos = {
        lat: lat,
        lng: lng
      }
      
      await this.$gmapApiPromiseLazy().then( () => {
        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder()
        geocoder.geocode({ location: { lat: pos.lat, lng: pos.lng } }, (results, status) => {
          if (status === 'OK') {
            // console.log('set', results[0])
            this.setPlaceQuery(results[0])
            this.$refs.autocomplete.$refs.input.value = results[0].formatted_address
          }
          // console.log('res', results[0])
          // console.log('stat', status)
        })
      })
    },
    setPlace (place) {
      this.markersAdd = []
      this.place = place
      // console.log('place', place)
    },
    setPlaceQuery (place) {
      this.markersAdd = []
      this.place = place
      this.getData()
      // console.log('place', place)
    },
    setDragPlace (place) {
      this.place = place
      // console.log('place', place)
    },
    async getData() {
      this.markersAdd = []
      let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    onCancel: this.onCancel,
                    color: '#1976D2'
                });
      if (this.place) {
        this.dataRadius.position.lat = this.place.geometry.location.lat()
        this.dataRadius.position.lng = this.place.geometry.location.lng()
        this.markersAdd.push({
          position: {
            lat: this.place.geometry.location.lat(),
            lng: this.place.geometry.location.lng(),
          }
        })
        // console.log(this.place.geometry.location.lat())
        // this.place = null;
      }
      
      let query = {}
      query.lat = this.dataRadius.position.lat
      query.lng = this.dataRadius.position.lng
      query.radius = this.dataRadius.radius
      const param = {
        ...query
      }
      this.markers = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'odb-map', {params: param})
          .then(async response => {
            // console.log(response)
            loader.hide()
            
            this.$refs.circleRef.$circlePromise.then(() => {
                const {$circleObject} = this.$refs.circleRef; //get google.maps.Circle object
                const map = $circleObject.getMap();
                map.fitBounds($circleObject.getBounds());
            })
            return response.data
        })
    },
    toggleInfoWindow (marker, idx) {
      this.infoWindowPos = marker.position;
      const contentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            `<h5 id="firstHeading" class="firstHeading">${marker.label.text}</h5>` +
            '<div id="bodyContent">' +
            `Total Port: ${marker.totalPort} <br>` +
            `Empty Port: ${marker.emptyPort} <br>` +
            `Filled Port: ${marker.filledPort} ` +
            "</div>" +
            "</div>";
      // this.infoOptions.content = marker.nameOdb;
      this.infoOptions.content = contentString;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;

      }
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" 
              ref="formContainer">
          <div class="card-body search">
            <!-- <h4 class="card-title">Markers</h4>
            <p class="card-title-dsec">Example of google maps.</p> -->
            <!-- Map with markers -->
            <gmap-map ref="gmap"
              :center="dataRadius.position"
              :zoom="14"
              style="height: 700px"
              :options=optionsMap
              map-type-id="roadmap"
            >
              <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                asdasd
              </gmap-info-window>
              <gmap-marker
                v-for="(m, index) in markers"
                :key="index"
                :position="m.position"
                :clickable="true"
                :label= m.label
                :icon=markerOptions
                
                @click="toggleInfoWindow(m,index)"
              ></gmap-marker>
              <gmap-marker
                v-for="(m, index) in markersAdd"
                :key="'A'+ index"
                :position="m.position"
                :draggable="true"
                @dragend="getMarkerPosition($event)"
              ></gmap-marker>
              <!-- <gmap-custom-marker 
              v-for="(m, index) in markers"
                :key="index"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                label= "asd"
                
                @click="toggleInfoWindow(m,index)"
              >
                <img src="@/assets/images/logo-sm.png"/>
              </gmap-custom-marker> -->
              <gmap-circle ref="circleRef" v-if="markersAdd.length!=0" :center="{lat:dataRadius.position.lat, lng:dataRadius.position.lng}" :radius="dataRadius.radius" :options="mapCenterOptions"> </gmap-circle>
            </gmap-map>
            
            <!-- <input ref="autocomplete" id="autocomplete" placeholder="Search" /> -->
            <div class="row" id="autocomplete">
              <div class="col-6">
                <gmap-autocomplete  ref="autocomplete" :options="{fields: ['geometry']}" type="search" class="form-control rounded"
                  @place_changed="setPlace">
                  <i class="uil uil-search search-icon"></i>
                </gmap-autocomplete>
              </div>
              <div class="col-3">
                <input type="number" class="form-control" placeholder="Radius dalam Meter" v-model.number="dataRadius.radius"/>
              </div>
              <div class="col-3">
                <b-button variant="primary" @click="getData">
                  <i class="fas fa-search"></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Basic</h4>
            <p class="card-title-dsec">Example of google maps.</p>
            <GmapMap
              :center="{ lat: 2, lng: 2 }"
              :zoom="5"
              style="height: 300px"
            ></GmapMap>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Markers</h4>
            <p class="card-title-dsec">Example of google maps.</p>
            <gmap-map
              :center="{ lat: -7.870222456971836, lng: 112.52707035641698 }"
              :zoom="12"
              style="height: 300px"
            >
              <gmap-marker
                v-for="(m, index) in markers"
                :key="index"
                :position="m.position"
                :clickable="true"
                :draggable="true"
                @click="clickMap()"
              ></gmap-marker>
            </gmap-map>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Street View Panoramas</h4>
            <p class="card-title-dsec">Example of google maps.</p>
            <gmap-street-view-panorama
              style="height: 300px"
              :position="{ lat: 52.201272, lng: 0.11872 }"
              :pov="pov"
              :zoom="1"
              @pano_changed="updatePano"
              @pov_changed="updatePov"
            ></gmap-street-view-panorama>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Polygon Editing</h4>
            <p class="card-title-dsec">Example of google maps.</p>
            <gmap-map
              :center="{ lat: 1.38, lng: 103.8 }"
              :zoom="12"
              style="height: 300px"
            >
              <gmap-polygon
                :paths="paths"
                :editable="true"
                @paths_changed="updateEdited($event)"
              ></gmap-polygon>
            </gmap-map>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Map type</h4>
            <p class="card-title-dsec">Example of google maps.</p>
            <gmap-map
              :center="{ lat: 4, lng: 4 }"
              :zoom="3"
              style="height: 300px"
              map-type-id="terrain"
            ></gmap-map>
          </div>
        </div>
      </div>
    </div> -->
  </Layout>
</template>
<style>
/* ... */
#autocomplete {
  border-radius: 25px;
  font-size: 14px;
  height: 40px;
  padding: 0 10px;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 50%;
  z-index: 1;
}
.search {
  padding: 0;
}
</style>